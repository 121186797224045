import tinycolor from 'tinycolor2'
import { escape } from 'lodash-es'

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export async function asyncRunSafe<T = any>(fn: Promise<T>): Promise<[Error] | [null, T]> {
  try {
    return [null, await fn]
  }
  catch (e) {
    if (e instanceof Error)
      return [e]
    return [new Error('unknown error')]
  }
}

export const getTextWidthWithCanvas = (text: string, font?: string) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.font = font ?? '12px Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
    return Number(ctx.measureText(text).width.toFixed(2))
  }
  return 0
}

const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_'

export function randomString(length: number) {
  let result = ''
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

export function convertVideoUrl(videoUrl: string, segmentMetadata: any) {
  const isBilibili = videoUrl.includes('bilibili')
  const startTime = isBilibili ? (segmentMetadata?.bilibili_subtitle_summary?.start_time || '') : (segmentMetadata?.youtube_subtitle_summary?.start_time || '')
  const url = new URL(videoUrl)
  const pathName = url.pathname.endsWith('/') ? url.pathname : `${url.pathname}/`
  const origin = url.origin

  const convertToSeconds = (timeStr: string) => {
    const [minutes, seconds] = timeStr.split(':').map(Number)
    return minutes * 60 + seconds
  }
  return isBilibili ? `${origin}${pathName}?t=${convertToSeconds(startTime)}` : `${origin}${pathName}${url.search}&t=${convertToSeconds(startTime)}s`
}

export const getPurifyHref = (href: string) => {
  if (!href)
    return ''

  return escape(href)
}

export const generateRequestId = (): string => {
  // 获取当前UTC时间，并转换为北京时间（UTC+8）
  const now = new Date()
  const beijingTime = new Date(now.getTime() + 8 * 60 * 60 * 1000)
  // 格式化日期时间字符串，北京时间
  const nowStr = beijingTime.toISOString().replace(/-|:|T|\.|Z/g, '').slice(0, 17)
  // 生成随机字符串，长度为总长度36减去日期时间字符串的长度
  const randomStrLength = 36 - nowStr.length
  let randomStr = ''
  const characters = '0123456789abcdef'
  for (let i = 0; i < randomStrLength; i++)
    randomStr += characters.charAt(Math.floor(Math.random() * characters.length))

  return `${nowStr}${randomStr}`
}

export const isLightColor = (color?: string): boolean => {
  if (!color)
    return false
  const color1 = tinycolor(color)
  return color1.isLight()
}

export const getContentColor = (color?: string): string => {
  if (!color)
    return '#fff'

  if (isLightColor(color))
    return '#000'
  return '#fff'
}
